<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="['gaodeA']">
      <component :is="Component" />
    </keep-alive>
    <!-- <component
      :is="Component"
      :key="$route.path"
      v-if="!$route.meta.keepAlive"
    /> -->
  </router-view>
</template>

<style></style>
