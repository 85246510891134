import { KeepAlive } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/gaode-a',
  },
  {
    path: '/cesium',
    name: 'cesium',
    component: () => import('../views/cesium/cesium.vue'),
  },
  {
    path: '/three',
    name: 'three',
    component: () => import('../views/three/three.vue'),
  },
  {
    path: '/bmap',
    name: 'bmap',
    component: () => import('../views/map/b-map.vue'),
  },
  {
    path: '/mapv',
    name: 'mapv',
    component: () => import('../views/mapv/mapv.vue'),
  },
  {
    path: '/gaode',
    name: 'gaode',
    component: () => import('../views/gaode/gaode.vue'),
  },
  {
    path: '/gaode-a',
    name: 'gaodeA',
    component: () => import('../views/gaode/gaode-a.vue'),
    meta: {
      KeepAlive: true,
    },
  },
  {
    path: '/room',
    name: 'room',
    component: () => import('../views/three/room.vue'),
  },
  // -------------------------------
  {
    path: '/model-page',
    name: 'modelPage',
    component: () => import('../views/demos/3d-model.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
